import { NavLink } from "react-router-dom";

export default function AdminSideMenu() {
  const toggleMobileMenu = () => {
    let mobileMenu = document.getElementById("dashboard-mobile-admin-menu-btn");
    let mobileMenuBody = document.getElementById("dashboard-mobile-admin-menu-body");
    let overlay = document.getElementById("overlay-alpha");

    if (!mobileMenu.classList.contains("mobile-admin-menu-btn-open")) {
      mobileMenu.classList.add("mobile-admin-menu-btn-open");
      mobileMenuBody.classList.add("mobile-admin-menu-body-open");
      overlay.classList.add("overlay-active");
    } else {
      mobileMenu.classList.remove("mobile-admin-menu-btn-open");
      mobileMenuBody.classList.remove("mobile-admin-menu-body-open");
      overlay.classList.remove("overlay-active");
    }
  };

  const alertTest = () => {
    alert("testing");
  };

  return (
    <>
      {/** Large screen menu */}
      <div
        className="mt-3 desktop-side-menu"
        style={{
          border: "1px dotted #ccc",
          padding: "0px 10px 10px 10px",
          borderRadius: "10px",
        }}
      >
        <div
          className="p-2 mt-2 b-2 h4"
          style={{ color: "#ccc", borderBottom: "1px solid" }}
        >
          Dashboard Menu
        </div>
        <ul className="list-group" style={{listStyle: "none"}}> 
        <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/category"
              >
                Create Category
              </NavLink>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/product"
              >
                Create Product
              </NavLink>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/products"
              >
                View All Products
              </NavLink>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/view-emails"
              >
                View All Emails
              </NavLink>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/orders"
              >
                Manage Orders
              </NavLink>
            </li>
        </ul>
      </div>

      {/** Mobile screen menu */}
      <div
        id="dashboard-mobile-admin-menu-btn"
        className="mobile-side-menu mobile-menu-btn"
        onClick={() => toggleMobileMenu()}
      >
        Menu
      </div>
      <div
        style={{ border: "1px solid", height: "250px", padding: "5px 5px", left:"-240px", marginTop: '9px' }}
        id="dashboard-mobile-admin-menu-body"
        className="mobile-side-menu mobile-menu-items"
      >
        <div style={{}} className="list-group mobile-list-group admin-menu">
          <p style={{marginTop: '1rem', marginBottom:'4px'}}>Dashboard Menu</p>
          <ul style={{ listStyle: "none", marginLeft: "-20%", marginTop:"-11px !important;" }}>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/category"
              >
                Create Category
              </NavLink>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/product"
              >
                Create Product
              </NavLink>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/products"
              >
                View All Products
              </NavLink>
              <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/view-emails"
              >
                View All Emails
              </NavLink>
            </li>
            </li>
            <li>
              <NavLink
                className="list-group-item list-link"
                to="/dashboard/admin/orders"
              >
                Manage Orders
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
