import { useCart } from "../context/cart";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth";
import Jumbotron from "../components/cards/Jumbotron";
import moment from "moment";
import { useEffect } from "react";
import UserCartSidebar from "../components/cards/UserCartSidebar";
import ProductCardHorizontal from "../components/cards/ProductCardHorizontal";
import { checkCloseMobileMenu } from "../util/Util";
// import product from "../../../server/models/product";

export default function Cart() {
  const [cart, setCart] = useCart();
  const [auth, setAuth] = useAuth();

  useEffect(() => {
    checkCloseMobileMenu();
  },[])

  const navigate = useNavigate();

  const removeFromCart = (productId) => {
    let myCart = [...cart];
    let index = myCart.findIndex((item) => item.id === productId);
    myCart.splice(index, 1);
    setCart(myCart);
    /**
     * Local storage must be updated also
     */
    localStorage.setItem("cart", JSON.stringify(myCart));
  };

  const cartTotal = () => {
    let total = 0;
    cart.map((item) => {
      total += item.price;
    });

    return total.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  /**
   * USED TO KEEP THE PERSISANCE OF THE CART WHEN PAGE RELOADS
   */
  useEffect(() => {
    let existingCart = localStorage.getItem("cart");

    if (existingCart) setCart(JSON.parse(existingCart));
  }, []);

  return (
    <div style={{backgroundColor: "#191919"}}>

      <div className="container-fluid mb-0 page-component" style={{backgroundColor: "#191919"}}>
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="p-3 mt-2 mb-2 h4 text-center" style={{color: "#ccc"}}>
              {cart?.length > 0 ? (
                "Shopping Cart"
              ) : (
                <div className="text-center">
                  <h2 className="my-5">Your cart is empty</h2>
                  <button
                    className="btn btn-alpha"
                    onClick={() => navigate("/shop")}
                    style={{maxWidth: "300px"}}
                  >Add products to cart</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {cart?.length > 0 && (
        <div className="container" >
          <div className="row justify-content-between">
            <div className="col-md-7">
              <div className="row">
                {cart.map((p, index) => (
                  <ProductCardHorizontal
                    p={p}
                    index={index}
                    removeFromCart={removeFromCart}
                  />
                ))}
              </div>
            </div>
            <UserCartSidebar cartTotal={cartTotal()} />
          </div>
        </div>
      )}
    </div>
  );
}
