import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ContactEmailVerify = (props) => {
  const params = useParams();
  const [isRead, setIsRead] = useState(null);
  const [isReadAlready, setIsReadAlready] = useState(null);
  const [senderName, setSenderName] = useState("");

  const styles = {
    heading: {
      color: "#ccc",
      marginTop: "5px",
      marginTop: "4rem",
      marginBottom: "4rem",
    },
    button: {
        maxWidth: "300px"
    },
  };

  useEffect(() => {
    const token = params.token;

    async function markAsRead() {
      try {
        const { data } = await axios.post("/contact-us-verification", {
          token: token,
        });
        // console.log(data)
        setSenderName(data.senderName);
        setIsRead(true);
      } catch (err) {
        // console.log(err);
        // console.log(err.response.status);
        if (err.response.status == 400) {
          setIsReadAlready(true);
        }
        if (err.response.status == 404) {
          //Not found
        }
      }
    }

    markAsRead();
  }, []);

  return (
    <div className="App">
      {isRead && !isReadAlready && (
        <>
          <h1 style={styles.heading}>
            The email from {senderName} has been marked as read.
          </h1>
          <a href="/dashboard/admin/view-emails" style={styles.button} className="btn btn-warning">
            Click here to view other emails
          </a>
        </>
      )}
      {!isRead && !isReadAlready && (
        <>
          <h1 style={styles.heading}>
            There has been an error. Please try again.
          </h1>
          <a href="/dashboard/admin/view-emails" style={styles.button} className="btn btn-warning">
            Click here to view all emails
          </a>
        </>
      )}
      {isReadAlready && (
        <>
          <h1 style={styles.heading}>Email has already been marked as read.</h1>
          <a href="/dashboard/admin/view-emails" style={styles.button} className="btn btn-warning">
            Click here to view all emails
          </a>
        </>
      )}
    </div>
  );
};
