import { useAuth } from "../../context/auth";
import Jumbotron from "../../components/cards/Jumbotron";
// import UserSideMenu from "../../components/nav/UserSideMenu";
// import AdminSideMenu from "../../components/nav/AdminSideMenu";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ProductCardHorizontal from "../../components/cards/ProductCardHorizontal";
import { Select } from "antd";
import AdminSideMenu from "../../components/nav/AdminSideMenu";
import "./ManageOrders.css";
import { ConfirmBox } from "../../components/misc/ConfirmBox";

export default function ManageOrders() {
  /**
   * Access to global context/username
   */
  const [auth, setAuth] = useAuth();
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState([
    "Not processed",
    "Processing",
    "Shipped",
    "Delivered",
    "Cancelled",
  ]);

  const [changedStatus, setChangedStatus] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [oldValue, setOldValue] = useState(null);
  const [newValue, setNewValue] = useState(null);
  const [orderID, setOrderId] = useState(null);

  useEffect(() => {
    if (auth?.token) getAllOrders();
  }, [auth?.token]);

  const getAllOrders = async () => {
    try {
      /** Get orders from the endpoint */
      const { data } = await axios.get("/all-orders");
      setOrders(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  /** Update database with new status */
  // const handleChange = async (orderID, value) => {
  const handleChange = async (orderID, newValue, oldValue) => {
    setOldValue(oldValue);
    setNewValue(newValue);
    setOrderId(orderID);
    setShowConfirm(true);

    console.log(orderID, newValue, oldValue)

    // setChangedStatus(value);
    // try {

    //   // <ConfirmBox />
    //     /** Send value to the backend so it can update the database */
    //     const { data } = await axios.put(`/order-status/${orderID}`, {status: value});
    //     getAllOrders();
    // } catch (err) {
    //     console.log(err);
    // }
  };

  const optionConfirm = async () => {
    setChangedStatus(newValue);
    // console.log(newValue);
    try {
      
      /** Send value to the backend so it can update the database */
      const { data } = await axios.put(`/order-status/${orderID}`, {
        status: newValue,
      });
      getAllOrders();
    } catch (err) {
      console.log(err);
    }

    setShowConfirm(false)
  };

  const optionCancel = async () => {
    //backend endpoint to get the previous value:
    // let oldStatus = null;
    // try {
    //   const { data } = await axios.get(`/get-order-status/${orderID}`)
    //   console.log(data.status)
    //   oldStatus = data.status
    // } catch (error) {
    //   console.log(error)
    // }
    // setChangedStatus(oldStatus);
    // setShowConfirm(false)

    window.location.reload();
  };

  return (
    <>
      {showConfirm && <ConfirmBox optionConfirm={optionConfirm} optionCancel={optionCancel} />}

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <AdminSideMenu />
          </div>
          <div className="col-md-9">
            <div className="p-3 mt-2 b-2 h4 grey">User Orders</div>
            {orders?.map((o, i) => {
              return (
                <div
                  style={{ border: "1px dotted #ccc" }}
                  key={i}
                  className="shadow  rounded-4 mb-5 p-2"
                >
                  <table style={{}} className="table">
                    <thead className="thead">
                      <th style={{ padding: "0 11px" }} scope="col">
                        Order <br /> Number
                      </th>
                      <th style={{ padding: "0 11px" }} scope="col">
                        Status
                      </th>
                      <th style={{ padding: "0 11px" }} scope="col">
                        Buyer
                      </th>
                      <th style={{ padding: "0 11px" }} scope="col">
                        Ordered
                      </th>
                      <th style={{ padding: "0 11px" }} scope="col">
                        Payment
                      </th>
                      <th style={{ padding: "0 11px" }} scope="col">
                        Quantity
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {/* <Select style={{padding: "0 11px 0 -4px"}} bordered={false} defaultValue={o.status} onChange={(value) => handleChange(o._id, value)}> */}
                          <Select
                            style={{ padding: "0 11px 0 -4px" }}
                            bordered={false}
                            defaultValue={o.status}
                            onChange={(newValue) =>
                              handleChange(o._id, newValue, oldValue)
                            }
                          >
                            {status.map((s, i) => (
                              <Select.Option key={i}>{s}</Select.Option>
                            ))}
                          </Select>
                        </td>
                        <td>{o?.buyer?.name}</td>
                        <td>{moment(o?.createdAt).fromNow()}</td>
                        <td>{o?.payment?.success ? "Success" : "Failed"}</td>
                        <td>{o?.products?.length} product(s)</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="container">
                    <div className="row m-2">
                      {o?.products?.map((p, i) => (
                        <ProductCardHorizontal
                          key={i}
                          p={p}
                          isRemovable={false}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
