import React, { useEffect, useState } from "react";
import AdminSideMenu from "../../components/nav/AdminSideMenu";
import axios from "axios";
import { ViewEmailCard } from "../../components/cards/ViewEmailCard";

/**
 * @author
 * @function ViewEmails
 **/

export const ViewEmails = () => {
  const [listOfEmails, setListOfEmails] = useState([]);
  const [listOfMessages, setListOfMessages] = useState([]);
  const [listOfSubscribers, setListOfSubscribers] = useState([]);
  const styles = {
    font: {
      color: "#ccc",
    },
  };

  useEffect(() => {
    const getEmails = async () => {
      const { data } = await axios.get("/all-emails");
      //   console.log(data);
      setListOfEmails(data);
      setListOfMessages(data[0]);
      setListOfSubscribers(data[1]);
    //   console.log(listOfEmails);
    };

    getEmails();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <AdminSideMenu />
          </div>
          <div
            className="col-md-9 mx-auto"
            style={{ width: "50%", maxWidth: "500px" }}
          >
            <h1
              class="p-3 mt-2 b-2 h4 mx-auto d-flex justify-content-center"
              style={styles.font}
            >
              Messages sent to us:
            </h1>

            {listOfMessages.length > 0 ? (
              listOfMessages.map((email, index) => (
                <ViewEmailCard data={email} key={index} />
              ))
            ) : (
              <p>There are no messages sent</p>
            )}

            <h1
              class="p-3 mt-2 b-2 h4 mx-auto d-flex justify-content-center"
              style={styles.font}
            >
              Email list subscribers:
            </h1>

            {listOfSubscribers.length > 0 ? (
              listOfSubscribers.map((email, index) => (
                <ViewEmailCard data={email} key={index} />
              ))
            ) : (
              <p>There are no email list subscribers</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
