import { useAuth } from "../../context/auth"
import Jumbotron from "../../components/cards/Jumbotron"
import UserSideMenu from "../../components/nav/UserSideMenu";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import AdminSideMenu from "../../components/nav/AdminSideMenu";
import { AdminViewAllProductsCards } from "../../components/cards/AdminViewAllProductsCard";

export default function AdminProducts(){
    /**
     * Access to global context/username
     */
    const [auth, setAuth] = useAuth();
    const [products, setProducts] = useState([]);

    const loadProducts = async () => {
        try {
            const { data } = await axios.get("/products");
            setProducts(data);
        } catch (err) {
            console.log(err);
        }
    }

    

    useEffect(() => {
        loadProducts();
    },[])

    return(
        <>
        
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <AdminSideMenu />
                </div>
                <div className="col-md-9">
                <div className="p-3 mt-2 b-2 h4 mx-auto d-flex justify-content-center" style={{color: "#ccc"}}>Products</div>
                    <div className="p-3 mt-2 mb-2 row">
                        {
                            products.map((p) => (
                            <Link
                            key={p._id}
                            to={`/dashboard/admin/product/update/${p.slug}`}
                            style={{textDecoration: "none"}}
                            className="col-6"
                            >
                                <AdminViewAllProductsCards p={p} />
                                
                            </Link>))
                        }
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}