import React, { useEffect, useState } from "react";
import { checkCloseMobileMenu } from "../util/Util";
import "./Contact.css";
import axios from "axios";
import toast from "react-hot-toast";

/**
 * @author
 * @function Contact
 **/

export const Contact = (props) => {
  useEffect(() => {
    checkCloseMobileMenu();
  }, []);
  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   message: ''
  // });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    // const { name, value } = e.target;
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value
    // }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send formData to an API)
    // console.log('Form submitted:', formData);

    if (name.trim() == "" || email.trim() == "" || message.trim() == "") {
      toast.error("Please fill out all fields");
    }

    try {
      const result = await axios.post("/contact-us", {
        name,
        email,
        subject,
        message,
      });
      if (result){
        toast.success("Message saved in the DB");
        setIsSent(true);
      } 
      
    } catch (err) {
      console.log(err);
    }

    // axios
    //   .post("/contact-us", {
    //     name,
    //     email,
    //     message,
    //   })
    //   .then((response) => {
    //     if (response.ok) {
    //       alert("Email sent successfully!");
    //       // setFormData({ name: '', email: '', message: '' });
    //     } else {
    //       alert("Failed to send email.");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //     alert("Error sending email.");
    //   });
  };

  return (
    <div className="App">
      <h1 className="mt-3" style={{ color: "#CCC" }}>
        Contact Us
      </h1>
      {
        !isSent && 
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div style={{ marginTop: "2rem" }}>
            <label htmlFor="email">Subject:</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button className="mt-5 btn btn-warning" type="submit">
            Send Message
          </button>
        </form>
      }
      {
        isSent && (
          <>
            <h2>Thank you for your message.</h2>
          </>
        )
      }
    </div>
  );
};
