import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingGIF from "../../images/loading.gif";
import Loading from "../../components/routes/Loading";
// import EmailListUser from "../../../../backend/models/emailListUser";

/**
 * @author
 * @function Verify
 **/

export default function Verify(props) {
  const params = useParams();
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const token = params.token;

    async function verifyEmail() {
      try {
        const { data } = await axios.post("/email-verification", {
          token: token,
        });
        console.log(data);
        setIsVerified(true);
      } catch (err) {
        console.log(err);
        setIsVerified(false);
      }
    }

    verifyEmail();
  }, []);

  return (
    <>
      <div style={{ height: "90vh", backgroundColor: "#191919" }}>
        <p className="d-flex justify-content-center text-white mt-5">Verify</p>
        {/* TODO 
            MUST PASS PROPS INTO <LOADING /> TO TELL IT WHICH PAGE TO SEND THE USER TO AND HOW MANY SECONDS TO WAIT
        
        */}
        {isVerified && (
          <>
            <p>Welcome and thank you. Your email has been verified.</p>
            <Loading countDown={8} redirectPage={"/"} />
          </>
        )}
        {!isVerified && (
          <>
            <p>Error verifying email.</p>
            <Loading countDown={8} redirectPage={"/"} />
          </>
        )}
      </div>
      {console.log(params.token)}
    </>
  );
}
