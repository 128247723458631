import React, { useEffect, useState } from "react";
import splash from "../images/collage-s-logo.png";
import cropTop from "../images/crop-top.png";
import shirt from "../images/shirt.png";

import splash0 from "../images/collage-rip-0.png";
import splash1 from "../images/collage-rip-1.png";
import splash2 from "../images/collage-rip-2.png";
import splash3 from "../images/collage-rip-3.png";
import { checkCloseMobileMenu } from "../util/Util";
import { FadeInSection } from "../util/FadeInSection";
import { AboutUs } from "../components/sections/AboutUs";
import  FeaturedItems  from "../components/sections/FeaturedItems";
import Categories from "../components/sections/Categories";
import { NavLink } from "react-router-dom";

/**
 * @author
 * @function HomeCharlie
 **/

export const HomeCharlie = (props) => {
  //   const images = [
  //     {
  //       src: "https://placido-dev-online-store.myshopify.com/cdn/shop/files/shirt-1_large.png?v=1702867760",
  //       alt: "ALT 1",
  //     },
  //     {
  //       src: "https://placido-dev-online-store.myshopify.com/cdn/shop/files/hoodie_ade58185-4263-42ad-a655-aba2392341a6_large.png?v=1707165386",
  //       alt: "ALT 2",
  //     },
  //     {
  //       src: cropTop,
  //       alt: "ALT 3",
  //     },
  //     {
  //       src: shirt,
  //       alt: "ALT 4",
  //     },
  //   ];

  const images = [
    {
      src: splash0,
      alt: "ALT 1",
    },
    {
      src: splash1,
      alt: "ALT 2",
    },
    {
      src: splash2,
      alt: "ALT 3",
    },
    {
      src: splash3,
      alt: "ALT 4",
    },
  ];

  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [animateNow, setAnimateNow] = useState(false);

  // let currentImageIndex = 0;

  useEffect(() => {
    setAnimateNow(true);
    checkCloseMobileMenu();
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      // currentImageIndex = (currentImageIndex + 1) % images.length;
      // currentImageIndex += 1;
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {/* // LARGE SCREENS */}
      <div
        className="d-none d-md-flex d-lg-flex"
        style={{
          //   background: "rgb(204, 204, 204)",
          background: "var(--background-color)",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          className={`splash-charlie img-fluid ${animateNow && "fade-in"}`}
          src={splash}
          alt="splash"
        />
        <div
          className={`splash-text-contain-box d-none d-md-flex d-lg-flex flex-column align-center ${
            animateNow && "float-up"
          }`}
        >
          <h1
            style={{
              fontSize: "2.5rem",
              fontFamily: "system-ui",
              marginBottom: "25px",
            }}
          >
            Explore our collection
          </h1>
          <NavLink to="/shop" className={`btn btn-charlie mx-auto`}>Shop Now</NavLink>
        </div>
      </div>
      {/* SMALL SCREENS */}
      <div
        style={{
          backgroundColor: "var(--background-color)",
          marginBottom: "150px",
        }}
      >
        <div className="col-12 images-container  d-flex d-md-none d-lg-none justify-content-center">
          {images.map((image, index) => (
            <img
              key={index}
              className="img-fluid fade-img-sm"
              loading="lazy"
              style={{ opacity: index === currentImageIndex ? 0.8 : 0 }}
              src={image.src}
              alt={image.alt}
            />
          ))}
        </div>
        <div
          className={`splash-text-contain-box-sm  d-flex d-md-none d-lg-none flex-column align-center}`}
        >
          <h1
            style={{
              fontSize: "2.4rem",
              fontFamily: "system-ui",
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          >
            Explore our collection
          </h1>
          <NavLink to="/shop" className={`btn btn-charlie mx-auto`}>Shop Now</NavLink>
        </div>
      </div>

      {/* <Categories /> */}

      <FadeInSection>
        <FeaturedItems />
      </FadeInSection>

      <FadeInSection>
        <AboutUs />
      </FadeInSection>

      
    </>
  );
};
