import { Badge } from "antd";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/cart";
import toast from "react-hot-toast";

export default function ProductCard({ product, additionalClass }) {
  //Context
  const [cart, setCart] = useCart();

  //Hook
  const navigate = useNavigate();

  return (
    <div key={product._id} className={`card hoverable mb-3 card-outline ${additionalClass}`}>
      <Badge.Ribbon text={`${product.sold} sold`} color="red">
        <Badge.Ribbon
          text={`${
            product.quantity > 0
              ? product.quantity + " In stock"
              : "Out of stock"
          }`}
          color="green"
          placement="start"
        >
          
          <img
            className="card-img-top img-fluid"
            style={{ height: "300px", objectFit: "cover" }}
            src={
              process.env.NODE_ENV === "development"
                ? `${process.env.REACT_APP_API}/products/photos/${product._id}`
                : `/api/products/photos/${product._id}`     
            }
            onClick={() => navigate(`/products/${product.slug}`)}
            alt={product.name}
          />
        </Badge.Ribbon>
      </Badge.Ribbon>

      <div className="card-body">
        <h5>{product.name}</h5>

        <h4 className="fw-bold">
          {product.price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </h4>

        <p className="card-text">{product.description.substring(0, 10)}...</p>
      </div>

      <div className="d-flex justify-content-between">
        <button
          className="btn btn-warning col card-button"
          style={{ borderBottomLeftRadius: "5px" }}
          onClick={() => navigate(`/products/${product.slug}`)}
        >
          View Product
        </button>
        <button
          className="btn btn-outline-success col card-button grey-outline"
          style={{ borderBottomRightRadius: "5px", color: "#ccc" }}
          onClick={() => {
            setCart([...cart, product]);
            localStorage.setItem("cart", JSON.stringify([...cart, product]));
            toast.success("Product added to cart")
            //  toast.succes("Added to cart")
          }}
        >
          Add to Cart
        </button>
      </div>
      {/* <p>{moment(product.createdAt).fromNow()}</p>
      <p>{product.sold} Sold</p> */}
    </div>
  );
}
