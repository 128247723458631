import { useState, useEffect } from "react";
import AdminSideMenu from "../../components/nav/AdminSideMenu";
import { useAuth } from "../../context/auth";
import axios from "axios";
import toast from "react-hot-toast";
import CategoryForm from "../../components/forms/CategoryForm";
import { Modal } from "antd";

export default function ManageCategory() {
  const [auth, setAuth] = useAuth();
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updatingName, setUpdatingName] = useState("");

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const { data } = await axios.get("/categories");
      console.log(data);
      setCategories(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/category", { name });
      /**
       * Error
       */
      if (data?.error) {
        toast.error(data.error);
      } else {
        /**
         * Success
         */
        toast.success(`"Product category ${name}" is created`);
        setName("");
        console.log(data);
        loadCategories();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data._message);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.delete(`/category/${selected._id}`);
      /**
       * Error
       */
      if (data?.error) {
        toast.error(data.error);
      } else {
        /**
         * Success
         */
        toast.success(`"Product category ${name}" is deleted`);
        setName("");
        console.log(data);
        loadCategories();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data._message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const { data } = await axios.put(`/category/${selected._id}`, {
      name: updatingName,
    });
    if(data?.error){
        /**
         * Error updating
         */
        toast.error(data.error);
    }else{
        /**
         * Success updating
         */
        toast.success(`${data.name} is updating`);
        loadCategories();
        setVisible(false);
    }
    try {
    } catch (err) {}
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <AdminSideMenu />
          </div>
          <div className="col-md-9">
            <div className="p-3 mt-2 b-2 h4" style={{color: "#ccc"}}>Manage Product Categories</div>

            <CategoryForm
              value={name}
              setValue={setName}
              handleSubmit={handleSubmit}
              submitText="Submit"
            />

            <hr />
            <h4 className="mt-4">List of Categories</h4>

            <div className="col">
              {categories.map((c) => (
                <button
                  key={c._id}
                  className="btn btn-outline-primary m-3"
                  onClick={() => {
                    setVisible(true);
                    setSelected(c);
                    setUpdatingName(c.name);
                  }}
                >
                  {c.name}
                </button>
              ))}
            </div>
          </div>

          <Modal
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={null}
          >
            <CategoryForm
              value={updatingName}
              setValue={setUpdatingName}
              handleSubmit={handleUpdate}
              submitText="Update"
              isDeleteBtn={true}
              handleDelete={handleDelete}
            />
          </Modal>
        </div>
      </div>
    </>
  );
}
