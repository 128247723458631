import React, { useEffect, useState } from "react";

/**
 * @author
 * @function FeaturedItems
 **/

const FeaturedItems = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  // Function to fetch featured products (currently a placeholder)
  const getFeaturedProducts = async () => {
    // Placeholder for future API call
    // await axios.get("/getFeaturedEndpoint");
  };

  // Placeholder featured products
  const featured = [
    {
      src: "https://placido-dev-online-store.myshopify.com/cdn/shop/files/hat-0_large.png?v=1702867760",
      alt: "ALT 1",
      name: "Hat 1",
      width: "185px"
    },
    {
      src: "https://placido-dev-online-store.myshopify.com/cdn/shop/files/hoodie-0_large.png?v=1702867760",
      alt: "ALT 2",
      name: "Hoodie 1",
      width: "185px"
    },
    {
      src: `${process.env.REACT_APP_API}/products/photos/6621b09decdbbfb458232615`,
      alt: "ALT 3",
      name: "Hoodie 2",
    },

  ];

  // Component to display individual featured product
  const FeaturedProductCard = ({ prod }) => {
    return (
      <div className="featured-product-card">
        <img
        src={prod.src}
        alt={prod.alt}
        style={{
          width: prod.width ? prod.width : "150px", // Conditionally apply custom style
          margin: "20px"
        }}
      />
      <div className="view-featured-overlay">
        <p className="d-flex justify-content-center">View Product</p>
      </div>
        {/* <p>{prod.name}</p> */}
      </div>
    );
  };

  // useEffect hook to fetch products when the component mounts
  useEffect(() => {
    getFeaturedProducts();
  }, []);

  return (
    <div style={{ backgroundColor: "#f0f0f0", marginTop: "100px" }}>
      <h2 className="mt-5 d-flex justify-content-center">Featured Items</h2>
      <div style={{padding: "20px", marginTop: '50px', paddingTop: '10px', paddingBottom: '22px'}} className="d-flex flex-wrap justify-content-evenly">
        {featured.map((prod, index) => (
          <FeaturedProductCard key={index} prod={prod} />
        ))}
      </div>
    </div>
  );
};

export default FeaturedItems;
